import React from "react";

const NotFound: React.FC = () => {
  return (
    <>
      <h1 style={{ textAlign: "center", fontSize: "50px" }}>
        Error 404: Page not found
      </h1>
    </>
  );
};

export default NotFound;
